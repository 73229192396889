<template>
    <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2" class="mt-2 mr-2 ml-2 mb-2">
            <v-dialog width="600px" v-model="valueDialog" v-if="currentItem">
        <AssetValue :asset="currentItem" @onClose="valueDialog = false"></AssetValue>
      </v-dialog>
            <v-card-text>
                <v-data-table :search="search" :loading="loading" :headers="headers" :items="items"
                    :items-per-page="-1">
                    <template v-slot:top>
                        <v-row>
                            <v-col cols="12" md="2">
                                <v-text-field label="Search" v-model="search" solo dense
                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select v-model="selectedAssetType" solo dense :items="assetTypeItems"
                                    label="Asset Type"></v-select>
                            </v-col>
                            <v-spacer />
                            <v-col cols="12" md="auto">
                                <v-btn outlined color="primary"
                                    @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`, { headers: headers, items: asset })"><v-icon>mdi-export</v-icon>Export</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.alarm="{ item }">
                        <v-tooltip bottom v-if="item.alarm">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog(item)"><v-icon
                                        class="blink">mdi-alert</v-icon></v-btn>
                            </template>
                            <span>แจ้งเตือน</span>
                        </v-tooltip>

                        <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog(item)"><v-icon
                                        color="green">mdi-check-bold</v-icon></v-btn>
                            </template>
                            <span>ไม่พบการแจ้งเตือน</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <span v-if="item.interface !== 'inknow'"
                            :class="(item.status === 'Online') ? 'green--text font-weight-bold' : 'red--text font-weight-bold'">{{
                            item.status }}</span>
                        <span v-else class='green--text font-weight-bold'>Online</span>
                    </template>
                    <template v-slot:item.heartbeat="{ item }">
                        <span>{{ $dayjs(item.heartbeat).format('DD MMM YYYY HH:mm') }}</span>
                    </template>
                    <template v-slot:item.assetName="{ item }">
                        <span :class="(item.maintenance) ? 'grey--text' : ''">{{ item.assetName }}</span> 
                        <v-icon v-if="item.maintenance" small>mdi-wrench</v-icon>
                        <v-icon v-if="!item.notify" small color="red">mdi-volume-off</v-icon>
                    </template>
                    <template v-slot:item.settings="{ item }">
                        <v-btn icon @click="openEditDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <template v-slot:item.email="{ item }">
                        <v-btn icon @click="openEmailDialog(item)"><v-icon>mdi-email</v-icon></v-btn>
                    </template>
                    <template v-slot:item.history="{ item }">
                        <v-btn icon @click="openHistoryDialog(item)"><v-icon>mdi-table</v-icon></v-btn>
                    </template>
                    <template v-slot:item.alertHistory="{ item }">
                        <v-btn icon @click="openAlertHistoryDialog(item)"><v-icon>mdi-history</v-icon></v-btn>
                    </template>
                    <template v-slot:item.value="{ item }">
                        <v-btn icon @click="openValueDialog(item)"><v-icon color="primary">mdi-gauge</v-icon></v-btn>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <ASSET_MENU :asset="item"/>
                    </template>
                  
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-hover>
</template>
<script>
import ASSET_MENU from '@/components/asset/asset_menu'
import AssetValue from '@/components/asset/asset_value.vue'
export default {
    components: {
        ASSET_MENU,
        AssetValue
    },
    data() {
        return {
            headers: [
            ],
            selectedAssetType: null,
            loading: false,
            search: '',
            interval: null,
            posting: false,
            currentItem: null,
            valueDialog: false,
        }
    },
    mounted() {
        this.setHeader()
    },
    destroyed() {

    },
    computed: {
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
        items: {
            get: function () {
                return this.$store.state.Asset.assetAll.filter(el => (el.maintenance === true || el.notify === false))
            }
        }
    },
    methods: {
        openValueDialog(item) {
            this.currentItem = item
            this.valueDialog =true
        },
        setHeader() {
            if (this.role === 'Admin') {
                this.headers = [
                    { text: 'Alarm', value: 'alarm' },
                    { text: 'Asset Name', value: 'assetName' },
                    { text: 'Status', value: 'status' },
                    { text: 'Device ID', value: 'deviceId' },
                    { text: 'Asset Type', value: 'assetType' },
                    { text: 'Location', value: 'location' },
                    { text: 'Lasted Report', value: 'heartbeat' },
                    { text: 'Value', value: 'value' },
                    { text: 'Action', value: 'action', width: '300px' },
                ]
            } else {
                this.headers = [
                    { text: 'Alarm', value: 'alarm' },
                    { text: 'Asset Name', value: 'assetName' },
                    { text: 'Status', value: 'status' },
                    { text: 'Device ID', value: 'deviceId' },
                    { text: 'Asset Type', value: 'assetType' },
                    { text: 'Location', value: 'location' },
                    { text: 'Lasted Report', value: 'heartbeat' },
                    { text: 'Value', value: 'value' },
                    { text: 'Action', value: 'action', width: '300px' },
                ]
            }
        }
    },
}

</script>