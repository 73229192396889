<template>
  <v-row>
    <v-col cols="12">
      <v-dialog v-model="historyDialog" v-if="currentItem" scrollable>
        <DataHistory :asset="currentItem" @onClose="historyDialog = false"></DataHistory>
      </v-dialog>
      <v-dialog width="600px" v-model="editDialog" v-if="currentItem" scrollable>
        <AssetEdit :asset="currentItem" @onClose="editDialogClose"></AssetEdit>
      </v-dialog>
      <v-dialog v-model="alertDialog" v-if="currentItem" scrollable>
        <AlertSetting :asset="currentItem" @onClose="alertDialog = false"></AlertSetting>
      </v-dialog>
      <v-dialog v-model="tokenDialog" v-if="currentItem">
        <TokenSetting :asset="currentItem" @onClose="tokenDialog = false"></TokenSetting>
      </v-dialog>
      <v-dialog v-model="emailDialog" v-if="currentItem">
        <EmailSetting :asset="currentItem" @onClose="emailDialog = false"></EmailSetting>
      </v-dialog>
      <v-dialog v-model="alertHistoryDialog" v-if="currentItem" scrollable>
        <AlertHistory :asset="currentItem" @onClose="alertHistoryDialog = false"></AlertHistory>
      </v-dialog>
      <v-hover v-slot="{ hover }">
        <v-card :elevation="hover ? 6 : 2">
          <v-card-text>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-divider vertical></v-divider>
              <template v-for="(item, index) in Object.keys(assetStatus)">
                <v-col cols="12" md="auto" :key="index">
                  <v-row dense>
                    <v-col cols="12" class="text-center">
                      <v-avatar tile>
                        <v-img contain :src="assetStatus[item][0].icon"></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" class="text-body-1 text-center font-weight-bold">
                      {{ item }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="auto">
                  <tr>
                    <td class="text-body-1 red--text font-weight-bold">
                      Alarm
                    </td>
                    <td class="text-body-1 red--text font-weight-bold text-center">
                      {{ assetStatus[item].filter(val => val.alarm === true).length }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-body-1 green--text font-weight-bold ">
                      Online
                    </td>
                    <td class="text-body-1 green--text font-weight-bold text-center">
                      {{ assetStatus[item].filter(val => val.status === 'Online').length }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-body-1 orange--text font-weight-bold ">
                      Offline
                    </td>
                    <td class="text-body-1 orange--text font-weight-bold text-center">
                      {{ assetStatus[item].filter(val => val.status === 'Offline').length }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-body-1 font-weight-bold">
                      Total
                    </td>
                    <td class="text-body-1 font-weight-bold">
                      {{ assetStatus[item].length }}
                    </td>
                  </tr>
                </v-col>
                <v-divider vertical></v-divider>
              </template>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="4" v-for="(item, index) in assets" :key="index">
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 6 : 2">
              <v-card-title class="text-h6 font-weight-bold">
                <v-list-item three-line>
                  <v-list-item-avatar tile size="75">
                    <v-img :src="item.icon" contain></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-tooltip bottom v-if="item.status === 'Online'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" size="32" color="primary">mdi-smoke-detector-variant</v-icon>
                        </template>
                        <span>ออนไลน์</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" size="32" color="red">mdi-smoke-detector-variant</v-icon>
                        </template>
                        <span>ออฟไลน์</span>
                      </v-tooltip>

                      {{ item.assetName }}

                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.location }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      ชั้น {{ item.floor }} {{ item.building }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>

                    <v-tooltip bottom v-if="item.alarm">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog(item)"><v-icon
                            class="blink">mdi-alert</v-icon></v-btn>
                      </template>
                      <span>แจ้งเตือน</span>
                    </v-tooltip>

                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="openAlertHistoryDialog(item)"><v-icon
                            color="green">mdi-check-bold</v-icon></v-btn>
                      </template>
                      <span>ไม่พบการแจ้งเตือน</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-card-title>
              <v-card-text class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      @click="openAlertHistoryDialog(item)"><v-icon>mdi-history</v-icon></v-btn>
                  </template>
                  <span>ประวัติแจ้งเตือน</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      @click="openHistoryDialog(item)"><v-icon>mdi-table</v-icon></v-btn>
                  </template>
                  <span>ประวัติข้อมูล</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      @click="openEditDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>แก้ไขข้อมูล</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      @click="openAlertDialog(item)"><v-icon>mdi-alert-plus</v-icon></v-btn>
                  </template>
                  <span>เงื่อนไขการแจ้งเตือน</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      @click="openTokenDialog(item)"><v-icon>$LineIcon</v-icon></v-btn>
                  </template>
                  <span>ไลน์</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon
                      @click="openEmailDialog(item)"><v-icon>mdi-email</v-icon></v-btn>
                  </template>
                  <span>อีเมล</span>
                </v-tooltip>

              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>


    </v-col>
  </v-row>
</template>

<script>
import DataHistory from '@/components/asset/asset_history.vue'
import AssetEdit from '@/components/asset/asset_edit.vue'
import AlertSetting from '@/components/asset/asset_alert_setting.vue'
import TokenSetting from '@/components/asset/asset_token_setting.vue'
import EmailSetting from '@/components/asset/asset_email_setting.vue'
import AlertHistory from '@/components/asset/asset_alert_history.vue'

export default {
  components: {
    AssetEdit,
    DataHistory,
    AlertSetting,
    TokenSetting,
    EmailSetting,
    AlertHistory
  },
  data() {
    return {
      historyDialog: false,
      editDialog: false,
      currentItem: null,
      alertDialog: false,
      tokenDialog: false,
      emailDialog: false,
      alertHistoryDialog: false
    }
  },
  computed: {
    assetStatus: {
      get: function () {
        return this.$store.state.Fire.assetStatus
      },
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      }
    },
    assets: {
      get: function () {
        return this.$store.state.Asset.assetFireAlarm
      },
    }
  },
  methods: {
    openHistoryDialog(item) {
      this.currentItem = item
      this.historyDialog = true
    },
    openEditDialog(item) {
      this.currentItem = item
      this.editDialog = true
    },
    openAlertDialog(item) {
      this.currentItem = item
      this.alertDialog = true
    },
    openTokenDialog(item) {
      this.currentItem = item
      this.tokenDialog = true
    },
    editDialogClose() {
      this.editDialog = false
      this.refresh()
    },
    openEmailDialog(item) {
      this.currentItem = item
      this.emailDialog = true
    },
    openAlertHistoryDialog(item) {
      this.currentItem = item
      this.alertHistoryDialog = true
    },
    refresh() {
      this.$store.dispatch('Asset/GET_ALL_ASSET', this.building).then(() => {

      }).catch((err) => {

        console.log(err)
      })
    }
  },
}

</script>

<style scss scoped>
@-webkit-keyframes argh-my-eyes {
  0% {
    color: #FFEBEE !important;
  }

  49% {
    color: #FFEBEE !important;
  }

  50% {
    color: #E53935 !important;
  }

  99% {
    color: #E53935 !important;
  }

  100% {
    color: #FFEBEE !important;
  }
}

@-moz-keyframes argh-my-eyes {
  0% {
    color: #FFEBEE !important;
  }

  49% {
    color: #FFEBEE !important;
  }

  50% {
    color: #E53935 !important;
  }

  99% {
    color: #E53935 !important;
  }

  100% {
    color: #FFEBEE !important;
  }
}

@keyframes argh-my-eyes {
  0% {
    color: #FFEBEE;
  }

  49% {
    color: #FFEBEE;
  }

  50% {
    color: #E53935;
  }

  99% {
    color: #E53935;
  }

  100% {
    color: #FFEBEE;
  }
}

.blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation: argh-my-eyes 2s infinite !important;
  animation: argh-my-eyes 2s infinite !important;
}
</style>