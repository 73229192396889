<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
            <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon color="green">mdi-weather-pouring</v-icon>Rain Water
        </template>
        <template v-slot:subtitle>
       ระบบน้ำฝน
      </template>
        </Head>
    <v-tabs centered v-model="tab"  v-if="sanitary_machine.length > 0">
    <v-tab v-if="main_level.length > 0 || main_meter.length >0 ">Dashboard</v-tab>
    <v-tab v-if="main_level.length > 0 || main_meter.length >0 ">History</v-tab>
    <v-tab>Machine</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab"  v-if="sanitary_machine.length > 0 ">
        <v-tab-item v-if="main_level.length > 0 || main_meter.length >0 ">
        <v-card flat>
          <v-card-text >
            <Dashboard/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="main_level.length > 0 || main_meter.length >0 ">
        <v-card flat>
          <v-card-text >
            <Table/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text >
            <MACHINE_VIEW :assets="sanitary_machine"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-card v-else flat >
      <v-card-title > <v-spacer/> This feature not available for your building<v-spacer/> </v-card-title>
      <v-img :src="require('@/assets/undraw_city_life_gnpr.svg')">
       
      </v-img>
    </v-card>

    </v-container>
  </template>
  
  <script>
  import Head from '@/components/layout/Head.vue'
  import Dashboard from '@/components/rain/sanitary_dashboard'
  import ColdWater from '@/components/rain/sanitary_coldwater'
  import WasteWater from '@/components/rain/sanitary_wastewater'
  import Table from '@/components/rain/sanitary_table'
  import Level from '@/components/rain/sanitary_level'
  import MACHINE_VIEW from '@/components/machine/machine_view'

    export default {
      name: 'HelloWorld',
      components: {
        Head,
        Dashboard,
        ColdWater,
        WasteWater,
        Table,
        Level,
        MACHINE_VIEW
      },
      computed: {
        main_meter() {
          return this.$store.state.RainWater.mainWaterMeter
        },
        main_level() {
          return this.$store.state.RainWater.mainWaterLevel
        },
        sanitary_machine() {
          return this.$store.state.Asset.assetAll.filter(el =>  el.machine === true && el.serviceId === 'be96e932-f9b4-47bb-812c-b32f3f8e6d0d')
        }
      },
      data() {
        return {
            tab: null,
        }
      },
    }
  </script>
  