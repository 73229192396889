<template>
    <v-row class="" dense>
    <template v-for="(asset, index) in assets" >
    <v-col cols="12" class="mt-2" :key="`level` + index">
      <SANITARY_TANK_WIDGET :asset="asset"/>
    </v-col>
  </template>
  <template v-for="(asset, index) in main_meter" >
    <v-col cols="12" class="mt-2" :key="`meter`+index">
      <SANITARY_METER_WIDGET :asset="asset"/>
    </v-col>
  </template>
    </v-row>
   </template>
   
   <script>
   import SANITARY_TANK_WIDGET from '@/components/sanitary/sanitary_tank_widget';
   import SANITARY_METER_WIDGET from '@/components/sanitary/sanitary_meter_widget';
   export default {
       components: {
        SANITARY_TANK_WIDGET,
        SANITARY_METER_WIDGET
       },
       data() {
        return {
          selectedAsset: []
        }
       },
      computed: {
       main_meter() {
          return this.$store.state.HotWater.mainWaterMeter
        },
       assets() {
            return this.$store.state.Asset.assetHotWater.filter((el)=> el.assetType === 'WaterLevel')
        }
       },
   }
   
   </script>