import { getAsset, getTelemetry, postAsset, getAlertLatest, getAssetType, getServices, anomalyLearning } from '@/api/'
import Vue from 'vue'
import * as alasql from 'alasql'
import { EventBus } from '@/plugins/event-bus'

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByAssetType = groupBy('assetType')

const state = () => ({
    assetList: [],
    assetAll: [],
    assetAQI: [],
    assetEnergy: [],
    assetFireAlarm: [],
    assetMachine: [],
    assetSanitary: [],
    assetWaste: [],
    assetWasteWater: [],
    assetEnergyGroup: [],
    assetHotWater: [],
    assetRainWater: [],
    assetSoftWater: [],
  })

const mutations = {
    SET_ASSET_LIST(state, {assetList, alert, assetType}){
      assetList.forEach((asset)=>{
          if (asset.heartbeat) {
            let heartbeat  = Vue.prototype.$dayjs(asset.heartbeat)
            let current = Vue.prototype.$dayjs()
            let diffTime = current.diff(heartbeat,'m')
            if (diffTime > 425) {
              asset.status = 'Offline'
            } else {
              asset.status = 'Online'
            }
          }
          if(alert.filter(el=> el.assetId === asset.assetId).length > 0){
            asset.alarm = true
          }else{
            asset.alarm = false
          }

          try{
            if(assetType.filter(el=> el.partitionKey === asset.assetType).length > 0) {
              let type = assetType.filter(el=> el.partitionKey === asset.assetType)[0]
              if (type.machineState) {
                let state = type.machineState.split('|')
                if (state.length === 2) {
                  let data = JSON.parse(asset.data)
                  if (data) {
                   
                    let firstState = state[0].split(':')
                    let secondState = state[1].split(':')
                    if (Number(data[type.machine]) === Number(firstState[1])) {
                      asset.machineState = firstState[0]
                    } else if (Number(data[type.machine]) === Number(secondState[1])) {
                      asset.machineState = secondState[0]
                    } else {
                      asset.machineState = null
                    }
                  } else {
                    asset.machineState = null
                  }
                } else {
                  asset.machineState = null
                }
              } else {
                  let data = JSON.parse(asset.data)
                  if (data) {
                    asset['machineState'] = data[type.machine]
                  }
             
              }
            }
          } catch(e) {
            console.log(e)
          }
        
        })
        state.assetAll = assetList
        state.assetMachine =  assetList.filter(el=> el.machine === true)
        state.assetAQI = assetList.filter(el=> el.serviceId === '20340ea6-88dd-11ee-b9d1-0242ac120001')
        state.assetEnergy = assetList.filter(el=> el.serviceId === '20340ea6-88dd-11ee-b9d1-0242ac120003')
        state.assetSanitary = assetList.filter(el=> el.serviceId === '20340ea6-88dd-11ee-b9d1-0242ac120004')
        state.assetFireAlarm = assetList.filter(el=> el.serviceId === '20340ea6-88dd-11ee-b9d1-0242ac120002')
        state.assetWaste = assetList.filter(el=> el.serviceId === '78fe3ec8-4e94-4b19-8869-1c06417a8a19')
        state.assetHotWater = assetList.filter(el=> el.serviceId === 'a07187f5-c788-42f5-8285-8cd5e9c99c0f')
        state.assetSoftWater = assetList.filter(el=> el.serviceId === 'df062dab-9d10-4c0e-8b86-4a06b94eeb0d')
        state.assetRainWater = assetList.filter(el=> el.serviceId === 'be96e932-f9b4-47bb-812c-b32f3f8e6d0d')
        state.assetList = groupByAssetType(state.assetFireAlarm)
      } 
}

const actions = {
  GET_ASSET_TYPE({commit}) {
    return new Promise((resolve, reject)=> {
      getAssetType(message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_SERVICES({commit}) {
    return new Promise((resolve, reject)=> {
      getServices(message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  GET_TELEMETRY({commit, rootState}, data) {
    return new Promise((resolve, reject)=> {
      getTelemetry({buildingId: rootState.User.building.BuildingId, assetId: data.assetId,start: data.start, end:data.end}, message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  UPDATE_ANOMALY_LEARNING({commit, rootState}, data) {
    data.buildingId = rootState.User.building.BuildingId
    data.tenantId =  rootState.User.tenant.TenantId
    return new Promise((resolve, reject)=> {
      anomalyLearning(data, message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
  UPDATE_ASSET({commit, rootState}, data) {
    data.buildingId = rootState.User.building.BuildingId
    data.tenantId =  rootState.User.tenant.TenantId
    return new Promise((resolve, reject)=> {
      postAsset(data , message=>{
        resolve(message.data)
      }, error=> {
        reject(error)
      })
    })
  },
    GET_ALL_ASSET({commit,dispatch, rootState}, data) {
        return new Promise((resolve, reject) => {
          if (data.BuildingId) {
            getAsset({buildingId: data.BuildingId}, message => {
              getAlertLatest({buildingId: data.BuildingId}, alertData => {
                commit('SET_ASSET_LIST', {assetList: message.data, alert: alertData.data, assetType: rootState.User.assetType} )
                dispatch('Alert/SET_ALERT_LATEST', alertData.data,{ root: true})
                dispatch('Energy/SET_MAIN_METER',null,{ root: true })
                dispatch('Water/SET_MAIN_METER',null,{ root: true })
                dispatch('Waste/SET_MAIN_METER',null,{ root: true })
                dispatch('Water/SET_MAIN_LEVEL',null,{ root: true })
                dispatch('SoftWater/SET_MAIN_LEVEL',null,{ root: true })
                dispatch('RainWater/SET_MAIN_LEVEL',null,{ root: true })
                dispatch('HotWater/SET_MAIN_LEVEL',null,{ root: true }) 
                dispatch('Fire/SET_FLOOR',null,{ root: true })
                EventBus.$emit('asset', message.data)
                resolve(message.data)
              },error=>{
                reject(error)
              })
            }, error=> {
              reject(error)
            })
          } else {
            resolve()
          }
         
        })
      },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
  }
  