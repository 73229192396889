<template>
  <v-container fluid>

    <Head>
      <template v-slot:title>
        <v-icon>mdi-border-all</v-icon>Overview
      </template>
      <template v-slot:subtitle>
       ภาพรวมระบบของอาคาร
      </template>
    </Head>
    <v-row>
      <v-col cols="12" xs="6" md="3" xl="3"
            v-for="(item, index) in services.filter((el) => building.services.filter((sub) => sub.ServiceId === el.id).length)"
            :key="index">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 6 : 2" :to="{ name: item.navigate }" v-if="item.name !=='CMMS' && item.name !=='Work Permit'">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon :color="item.color" size="40" v-text="item.icons"></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-h5">{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="pt-1 text-title-1 text-wrap">{{ item.nameTH }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <template v-if="!item.systemName">
                    <v-list-item-action>
                      <v-list-item-action-text>{{ item.systemName }}</v-list-item-action-text>
                    </v-list-item-action>
                  <v-list-item-action v-if="(alertByService[item.id]) ? alertByService[item.id].length : 0">
                    <v-icon  size="25" class="blink" v-text="'mdi-alert'"></v-icon>
                    <v-list-item-action-text>พบการแจ้งเตือน</v-list-item-action-text>
                  </v-list-item-action>
                  <v-list-item-action v-else>
                    <v-icon color="green" size="25" class="font-weight-bold" v-text="'mdi-check'"></v-icon>
                    <v-list-item-action-text>ระบบปกติ</v-list-item-action-text>
                  </v-list-item-action>
                  </template>
                  <template v-else>
                    <v-list-item-action>
                      <v-list-item-action-text>Total: {{ items.length }}</v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-card>
              <v-card v-else :elevation="hover ? 6 : 2" target="_blank" :href="item.url">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon :color="item.color" size="40" v-text="item.icons"></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold text-h5">{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="pt-1 text-title-1 text-wrap">{{ item.nameTH }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action >
                    <v-list-item-action-text>{{ item.systemName }}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-hover>
          </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <v-card >
          <v-img :src="building.BuildingImage || require('@/assets/undraw_photos_re_pvh3.svg')">
            <v-card-title class="font-weight-bold" style="background: rgba(255, 255, 255, 0.8);">{{ building.BuildingName }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card outlined v-if="weather" class="mt-4">
                    <v-system-bar>คุณภาพอากาศ ภายนอกอาคาร</v-system-bar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4" class="text-center font-weight-bold">
                          <v-avatar size="100">
                            <v-icon size="100" v-if="weather.aqi < 50" color="green">mdi-emoticon-happy-outline</v-icon>
                            <v-icon size="100" v-else-if="weather.aqi < 100"
                              color="yellow">mdi-emoticon-neutral-outline</v-icon>
                            <v-icon size="100" v-else-if="weather.aqi < 200"
                              color="orange">mdi-emoticon-sad-outline</v-icon>
                            <v-icon size="100" v-else color="red">mdi-emoticon-frown-outline</v-icon>
                          </v-avatar>
                          <v-row>
                          </v-row>
                        </v-col>
                        <v-col cols="8" align-self="end">
                          <v-row dense class="font-weight-bold">
                            <v-col cols="4">AQI</v-col>
                            <v-col cols="8">{{ weather.aqi }}</v-col>
                            <v-col cols="4">PM2.5</v-col>
                            <v-col cols="8">{{ (weather.iaqi.pm25) ? weather.iaqi.pm25.v : '-' }} ug/m3</v-col>
                            <v-col cols="4">PM10</v-col>
                            <v-col cols="8"> {{ (weather.iaqi.pm10) ? weather.iaqi.pm10.v : '-' }} ug/m3</v-col>
                            <v-col cols="4">T.</v-col>
                            <v-col cols="8">{{ (weather.iaqi.t) ? weather.iaqi.t.v : '-' }}°C</v-col>
                            <v-col cols="4">Humidity</v-col>
                            <v-col cols="8">{{ (weather.iaqi.h) ? weather.iaqi.h.v : '-' }}%</v-col>
                            <v-col cols="4">Time</v-col>
                            <v-col cols="8"> {{ $dayjs(weather.time.v * 1000).format('DD MMM YYYY HH:mm') }}</v-col>
                            <v-col cols="4">Station</v-col>
                            <v-col cols="8"> {{ weather.city.name }}</v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card outlined v-if="indoorAQI" class="mt-4">
                    <v-system-bar>คุณภาพอากาศ ภายในอาคาร</v-system-bar>
                    <v-card-text>
                      <v-row>
                        <v-col cols="4" class="text-center font-weight-bold">
                          <v-avatar size="100">
                            <v-icon size="100" v-if="indoorAQI['PM25'] < 50"
                              color="green">mdi-emoticon-happy-outline</v-icon>
                            <v-icon size="100" v-else-if="indoorAQI['PM25'] < 100"
                              color="yellow">mdi-emoticon-neutral-outline</v-icon>
                            <v-icon size="100" v-else-if="indoorAQI['PM25'] < 200"
                              color="orange">mdi-emoticon-sad-outline</v-icon>
                            <v-icon size="100" v-else color="red">mdi-emoticon-frown-outline</v-icon>
                          </v-avatar>
                          <v-row>
                          </v-row>
                        </v-col>
                        <v-col cols="8" align-self="end">
                          <v-row dense class="font-weight-bold">
                            <v-col cols="4">PM2.5</v-col>
                            <v-col cols="8">{{ indoorAQI['PM25'] }} ug/m3</v-col>
                            <v-col cols="4">PM10</v-col>
                            <v-col cols="8"> {{ indoorAQI['PM10'] }} ug/m3</v-col>
                            <v-col cols="4">T.</v-col>
                            <v-col cols="8">{{ indoorAQI['Temperature'] }}°C</v-col>
                            <v-col cols="4">Humidity</v-col>
                            <v-col cols="8">{{ indoorAQI['Humidity'] }}%</v-col>
                            <v-col cols="4">CO2</v-col>
                            <v-col cols="8"> {{ indoorAQI['CO2'] }} ppm</v-col>
                            <v-col cols="4">Time</v-col>
                            <v-col cols="8"> {{ $dayjs().format('DD MMM YYYY HH:mm') }}</v-col>
                            <v-col cols="4">Location</v-col>
                            <v-col cols="8"> {{ assetAQI[0].location }}</v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <RadarChart v-if="!loading"/>
            <v-skeleton-loader v-else
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
          </v-col>
        

        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import Head from '@/components/layout/Head.vue'
import RadarChart from '@/components/overview/radar_chart'
import { mapState } from 'vuex'
export default {
  name: 'HelloWorld',
  components: {
    Head,
    RadarChart
  },
  computed: {
    ...mapState('User', ['user', 'tenant', 'roles']),
    ...mapState('Asset', [ 'assetAQI']),
    ...mapState('Alert', ['alertByService']),
    items: {
            get: function () {
                return this.$store.state.Asset.assetAll.filter(el => (el.maintenance === true || el.notify === false))
            }
        },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      }
    },
    indoorAQI() {
      if (this.assetAQI.length > 0) {
        return JSON.parse(this.assetAQI[0].data)
      } else {
        return null
      }
    },

  },
  watch: {
    building() {
      this.airQuality()
      this.refresh();
      this.getData() 
    },
  },
  async mounted() {
    //this.refresh();
    this.getData()
    await this.airQuality();
  },
  data() {
    return {
      services: [
        { name: 'Electrical', nameTH: 'ระบบไฟฟ้า', service: 'Smart Energy', icons: 'mdi-lightning-bolt-circle', navigate: 'electrical', subgroup: false, active: false, id: '20340ea6-88dd-11ee-b9d1-0242ac120003', color: 'primary', alarm: false},
        { name: 'Cold Water', nameTH: 'ระบบน้ำดี', service: 'Cold Water', icons: 'mdi-waves-arrow-up', navigate: 'ColdWater', subgroup: false, active: false, id: '20340ea6-88dd-11ee-b9d1-0242ac120004', color: 'blue', alarm: false },
        { name: 'Soft Water', nameTH: 'ระบบน้ำอ่อน', service: 'Soft Water', icons: 'mdi-grain', navigate: 'SoftWater', subgroup: false, active: false, id: 'df062dab-9d10-4c0e-8b86-4a06b94eeb0d', color: 'amber ', alarm: false },
        { name: 'Waste Water', nameTH: 'ระบบน้ำเสีย', service: 'Waste Water', icons: 'mdi-recycle', navigate: 'WasteWater', subgroup: false, active: false, id: '78fe3ec8-4e94-4b19-8869-1c06417a8a19', color: 'blue-grey', alarm: false },
        { name: 'Hot Water', nameTH: 'ระบบน้ำร้อน', service: 'Hot Water', icons: 'mdi-hot-tub', navigate: 'HotWater', subgroup: false, active: false, id: 'a07187f5-c788-42f5-8285-8cd5e9c99c0f', color: 'deep-orange ', alarm: false },
        { name: 'Rain Water', nameTH: 'ระบบน้ำฝน', service: 'Rain Water', icons: 'mdi-weather-pouring', navigate: 'RainWater', subgroup: false, active: false, id: 'be96e932-f9b4-47bb-812c-b32f3f8e6d0d', color: 'green', alarm: false },
        { name: 'HVAC', nameTH: 'ระบบปรับอากาศ', service: 'Smart Level', icons: 'mdi-hvac', navigate: 'hvac', subgroup: false, active: true, id: '5b3c30a7-978d-45e0-beda-0a1bf7ba8048', color: 'grey', alarm: false },
        { name: 'FireAlarm', nameTH: 'ระบบแจ้งเหตุเพลิงไหม้', service: 'Smart Alarm', icons: 'mdi-fire', navigate: 'fire_alarm', subgroup: true, active: true, id: '20340ea6-88dd-11ee-b9d1-0242ac120002', color: 'red', alarm: false },
        { name: 'AirQuality', nameTH: 'ระบบติดตามคุณภาพอากาศ', service: 'Air Quality', icons: 'mdi-air-filter', navigate: 'air_quality', subgroup: true, active: true, id: '20340ea6-88dd-11ee-b9d1-0242ac120001', color: 'teal darken-3', alarm: false },
        { name: 'Maintenance', nameTH: 'รายการอุปกรณ์ปิดปรับปรุง', service: 'Device Status', icons: 'mdi-volume-off', navigate: 'maintenance', subgroup: false, active: true, id: '5b3c30a7-978d-45e0-beda-0a1bf7ba8051', color: 'brown ', systemName: ' '},
        { name: 'CMMS', nameTH: 'ระบบแจ้งซ่อมและบำรุงรักษา', service: 'CMMS', icons: 'mdi-tools', navigate: 'cmms', subgroup: false, active: true, id: '5b3c30a7-978d-45e0-beda-0a1bf7ba8049', color: 'teal ', alarm: false, url: 'https://app.servicesf1.com/', systemName: 'Services F1' },
        { name: 'Work Permit', nameTH: 'ระบบใบอนุญาตเข้าพื้นที่', service: 'Work Permit', icons: 'mdi-security', navigate: 'workpermit', subgroup: false, active: true, id: '5b3c30a7-978d-45e0-beda-0a1bf7ba8050', color: 'cyan', alarm: false, url: 'https://workpermit.smartfem.app', systemName: 'Work Permit' },
      ],
      loading: false,
      weather: null
    }
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('Overview/GET_SCORE').then(()=>{
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
    async airQuality() {
      if (this.building.StationId) {
        const response = await fetch(`https://api.waqi.info/feed/${this.building.StationId}/?token=d88c969eb1941e4e73c7907ef8ae36ed88420b37`);
        const weather = await response.json();
        this.weather = weather.data
      } else {
        this.weather = null
      }

    },
    autoRefresh() {
      this.interval = setInterval(() => { this.refresh() }, 60000)
    },
    refresh() {
      this.$store.dispatch('Asset/GET_ALL_ASSET', this.building).catch((err)=>{
            console.log(err)
          })
    }
  },

}
</script>


<style scss scoped>
 @-webkit-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @-moz-keyframes argh-my-eyes {
    0%   { color: #FFEBEE !important; }
    49% { color: #FFEBEE !important; }
    50% { color: #E53935 !important; }
    99% { color: #E53935 !important; }
    100% { color: #FFEBEE !important; }
  }
  @keyframes argh-my-eyes {
    0%   { color: #FFEBEE; }
    49% { color: #FFEBEE; }
    50% { color: #E53935; }
    99% { color: #E53935; }
    100% { color: #FFEBEE; }
  }

  .blink {
  -webkit-animation: argh-my-eyes 2s infinite !important;
  -moz-animation:    argh-my-eyes 2s infinite !important;
  animation:         argh-my-eyes 2s infinite !important;
}


</style>