<template>
  <l-map v-resize="onResize" class="elevation-2" ref="map" :center="center" :crs="crs"
    :style="`height: ${height}px; width: 100%`" @ready="configMap()" @zoom="zoomHandled">
    <l-control-fullscreen position="topleft" :options="{ title: { 'false': 'Go big!', 'true': 'Be regular' } }" />
    <l-image-overlay :url="plant.image" :bounds="bounds" />
    <l-control-layers position="topright"> </l-control-layers>
    <l-marker v-if=item.position v-for="(item, index) in plant.asset"
      :lat-lng="[Number(item.position.split(':')[0]), Number(item.position.split(':')[1])]" :key="index" :options="{
        draggable: (isSuperAdmin) ? true : false,
      }" @dragend="draggableEvent($event, item)">
      <l-icon :ref="item.assetName"
        :icon-size="[Number(item.IconSize.split(':')[0]), Number(item.IconSize.split(':')[1])]" :icon-url="item.icon">
      </l-icon>
      <l-tooltip :options="{
        permanent: true,
        direction: 'bottom',
        offset: [-15, 40]
      }">
        <template v-if="item.machineState === 'Running'">
          <v-icon size="15" class="mr-2" color="green">mdi-circle</v-icon>
        </template>
        <template v-else>
          <v-icon size="15" class="mr-2" color="red">mdi-circle</v-icon>
        </template>{{ item.assetName }}
      </l-tooltip>

      <l-popup :options="{ autoClose: true, closeOnClick: true, maxWidth: 550 }">
        <HVAC_POPUP :asset="item" />
      </l-popup>
    </l-marker>
  </l-map>
</template>
<script>
import { CRS, icon } from "leaflet";
import { LMap, LImageOverlay, LMarker, LPopup, LPolyline, LTileLayer, LControl, LIcon, LTooltip, LControlLayers, LLayerGroup } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import { EventBus } from '@/plugins/event-bus'
import HVAC_POPUP from '@/components/hvac/hvac_popup.vue'
import Vue2LeafletEditablecirclemarker from 'vue2-leaflet-editablecirclemarker'

export default {
  props: {
    plant: {
      type: Object
    }
  },
  components: {
    LMap,
    LImageOverlay,
    LMarker,
    LPopup,
    LPolyline,
    LTileLayer,
    LControl,
    LIcon,
    LTooltip,
    LControlLayers,
    LLayerGroup,
    LControlFullscreen,
    HVAC_POPUP,
    'v-editablecirclemarker': Vue2LeafletEditablecirclemarker
  },
  computed: {
    isSuperAdmin: {
      get: function () {
        return this.$store.state.User.isSupperAdmin
      }
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220
        case 'sm': return 350
        case 'md': return 450
        case 'lg': return 600
        case 'xl': return 700
      }
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      },
    },
    mapLayers() {
      return this.building.services.filter((el) => el.ServiceId === '20340ea6-88dd-11ee-b9d1-0242ac120003')[0].layer
    },
    assets() {
      return this.$store.state.Asset.assetEnergy
    },
    role: {
      get: function () {
        return this.$store.state.User.role
      }
    },
    units() {
      return this.$store.state.User.units
    }

  },
  data() {
    return {
      crs: CRS.Simple,
      center: [this.plant.x, this.plant.y],
      bounds: [[0, 0], [this.plant.height, this.plant.width]],
      layers: [],
      posting: false,
      timeout: 2000,
    }
  },
  watch: {
    building() {
      this.configMap()
    }
  },
  mounted() {
    EventBus.$on('asset', this.refreshMap)
  },
  destroyed() {
    EventBus.$off('asset', this.refreshMap)
  },
  methods: {
    draggableEvent(event, item) {
      var latlng = event.target.getLatLng();
      item.position = `${latlng.lat}:${latlng.lng}`
    },
    refreshMap() {
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize();
      }, 5);
    },
    zoomHandled() {
    },
    onResize() {
    },
    configMap() {
      setTimeout(() => {

      }, 5);
    }
  },
}

</script>