<template>
    <v-row class="pt-2">
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2" :disabled="loading">
                    <v-card-title><v-spacer />
                        <v-col cols="12" md="3">
                            <v-select placeholder="เลือกอุปกรณ์" :disabled="loading" class="mt-6" dense solo
                                v-model="selectedMeter" :items="assets" item-text="assetName"
                                return-object></v-select></v-col>
                        <v-col cols="12" md="2"> <v-select :disabled="loading" class="mt-6" dense solo
                                v-model="selectedPeriod" :items="periods" item-text="period"
                                item-value="value"></v-select></v-col>
                        <v-col cols="12" md="auto">
                            <DateRangePicker class="mt-6" v-model="range" />
                        </v-col>
                        <v-col cols="12" md="auto">
                            <v-btn color="primary" :disabled="loading" @click="getWaterTable()"
                                :block="$vuetify.breakpoint.mobile">View</v-btn></v-col>
                        <v-col cols="12" md="auto">
                            <v-btn :disabled="loading" color="primary" outlined
                                @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`, { headers: headers, items: items })"
                                :block="$vuetify.breakpoint.mobile">Export</v-btn></v-col>
                    </v-card-title>
                </v-card>
            </v-hover>
        </v-col>
        <v-col cols="12">
            <BAR_CHART v-if="selectedMeter && selectedMeter.assetType === 'WaterMeter'" v-bind="series"
                :update="update" />
            <LEVEL_BAR_CHART v-else-if="selectedMeter && selectedMeter.assetType === 'WaterLevel'" v-bind="series"
                :update="update" />
        </v-col>
        <v-col cols="12">
            <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 6 : 2">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="items" :items-per-page="-1">
                            <template v-slot:item.Unit="{ item }">
                                {{ item.Unit.toFixed(2) }}
                            </template>
                            <template v-slot:item.date="{ item }">
                                {{ $dayjs(item.date, "YY-MM-DD").format('DD/MM/YYYY') }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-hover>
        </v-col>
    </v-row>
</template>
<script>
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import BAR_CHART from '@/components/charts/bar_chart.vue';
import LEVEL_BAR_CHART from '@/components/charts/bar_level_chart.vue';
import { xlsxMixins } from '@/mixins/xlsx'
export default {
    mixins: [xlsxMixins],
    components: {
        DateRangePicker,
        BAR_CHART,
        LEVEL_BAR_CHART
    },
    props: {
        update: {
            type: Boolean
        }
    },
    data() {
        return {
            heartbeats: [120, 100, 90, 110, 130],
            items: [],
            enable: true,
            selectedMeter: null,
            range: {
                startDate: this.$dayjs().startOf('month').toDate(),
                endDate: this.$dayjs().endOf('month').toDate(),
            },
            selectedPeriod: 'daily',
            periods: [
                { period: 'Hourly', value: 'hourly' },
                { period: 'Daily', value: 'daily' },
                // { period: 'Weekly', value: 'weekly' },
                // { period: 'Monthly', value: 'monthly' },
                // { period: 'Yearly', value: 'yearly' },
            ],
            loading: false,
            headers: [],
            series: {
                axis: [],
                unit: [],
                max: [],
                min: [],
                avg: [],
            },
        }
    },
    mounted() {
        this.selectedMeter = this.assets[0]
    },
    computed: {
        assets() {
            return this.$store.state.Asset.assetRainWater.filter((el) => el.assetType === 'WaterMeter' || el.assetType === 'WaterLevel')
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            },
        }
    },
    methods: {
        getWaterTable() {
            if (this.selectedMeter.assetType === 'WaterMeter') {
                this.loading = true
                this.$store.dispatch('Water/GET_WATER_CONSUMPTION', { BuildingId: this.building.BuildingId, type: this.selectedPeriod, deviceId: this.selectedMeter.deviceId, group: false, start: this.range.startDate.getTime(), end: this.range.endDate.getTime() }).then((data) => {
                    this.items = []
                    this.items = data
                    this.series.axis = []
                    this.series.unit = []
                    switch (this.selectedPeriod) {
                        case 'hourly':
                            this.headers = [
                                { text: 'Date', value: 'date' },
                                { text: 'H', value: 'H' },
                                { text: 'Total', value: 'Unit' },
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.date + '_' + element.H)
                                this.series.unit.push(Math.ceil(element.Unit))
                            })
                            break;
                        case 'daily':
                            this.headers = [
                                { text: 'Date', value: 'date' },
                                { text: 'Total', value: 'Unit' },
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.date)
                                this.series.unit.push(Math.ceil(element.Unit))
                            })
                            break;
                        case 'weekly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Week', value: 'week' },
                                { text: 'Total', value: 'Unit' },
                            ]
                            data = data.sort((a, b) => a.week - b.week)
                            data.forEach((element) => {
                                this.series.axis.push(element.week)
                                this.series.unit.push(Math.ceil(element.Unit))
                            })
                            break;
                        case 'monthly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Month', value: 'month' },
                                { text: 'Total', value: 'Unit' },
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(this.$dayjs().set('month', element.month - 1).format('MMM YY'))
                                this.series.unit.push(Math.ceil(element.Unit))
                            })
                            break;
                        case 'yearly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Total', value: 'Unit' }
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.year)
                                this.series.unit.push(Math.ceil(element.Unit))
                            })
                            break;
                    }



                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            } else if (this.selectedMeter.assetType === 'WaterLevel') {
                this.loading = true
                this.$store.dispatch('Water/GET_LEVEL', { BuildingId: this.building.BuildingId, type: this.selectedPeriod, assetId: this.selectedMeter.assetId, start: this.range.startDate.getTime(), end: this.range.endDate.getTime() }).then((data) => {
                    this.items = []
                    this.series.axis = []
                    this.series.unit = []
                    this.series.max = []
                    this.series.min = []
                    this.series.avg = []
                    switch (this.selectedPeriod) {
                        case 'hourly':
                            this.headers = [
                                { text: 'Date', value: 'date' },
                                { text: 'H', value: 'H' },
                                { text: 'Min (cm.)', value: 'MinLevel' },
                                { text: 'Max (cm.)', value: 'MaxLevel' },
                                { text: 'AVG (cm.)', value: 'AVGLevel' }
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.date + '_' + element.H)
                                element.AVGLevel = Math.ceil(element.AVGLevel)
                                this.series.min.push(Math.ceil(element.MinLevel))
                                this.series.max.push(Math.ceil(element.MaxLevel))
                                this.series.avg.push(Math.ceil(element.AVGLevel))
                            })
                            break;
                        case 'daily':
                            this.headers = [
                                { text: 'Date', value: 'date' },
                                { text: 'Min (cm.)', value: 'MinLevel' },
                                { text: 'Max (cm.)', value: 'MaxLevel' },
                                { text: 'AVG (cm.)', value: 'AVGLevel' }
                            ]
                            data.forEach((element) => {
                                this.series.axis.push(element.date)
                                element.AVGLevel = Math.ceil(element.AVGLevel)
                                this.series.min.push(Math.ceil(element.MinLevel))
                                this.series.max.push(Math.ceil(element.MaxLevel))
                                this.series.avg.push(Math.ceil(element.AVGLevel))
                            })
                            break;
                        case 'weekly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Week', value: 'week' },
                                { text: 'Min (cm.)', value: 'MinLevel' },
                                { text: 'Max (cm.)', value: 'MaxLevel' },
                                { text: 'AVG (cm.)', value: 'AVGLevel' }
                            ]
                            data = data.sort((a, b) => a.week - b.week)
                            data.forEach((element) => {
                                element.AVGLevel = Math.ceil(element.AVGLevel)
                                this.series.axis.push(element.week)
                                this.series.min.push(Math.ceil(element.MinLevel))
                                this.series.max.push(Math.ceil(element.MaxLevel))
                                this.series.avg.push(Math.ceil(element.AVGLevel))
                            })
                            break;
                        case 'monthly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Month', value: 'month' },
                                { text: 'Min (cm.)', value: 'MinLevel' },
                                { text: 'Max (cm.)', value: 'MaxLevel' },
                                { text: 'AVG (cm.)', value: 'AVGLevel' }
                            ]
                            data.forEach((element) => {
                                element.AVGLevel = Math.ceil(element.AVGLevel)
                                this.series.axis.push(this.$dayjs().set('month', element.month - 1).format('MMM YY'))
                                this.series.min.push(Math.ceil(element.MinLevel))
                                this.series.max.push(Math.ceil(element.MaxLevel))
                                this.series.avg.push(Math.ceil(element.AVGLevel))
                            })
                            break;
                        case 'yearly':
                            this.headers = [
                                { text: 'Year', value: 'year' },
                                { text: 'Min (cm.)', value: 'MinLevel' },
                                { text: 'Max (cm.)', value: 'MaxLevel' },
                                { text: 'AVG (cm.)', value: 'AVGLevel' }
                            ]
                            data.forEach((element) => {
                                element.AVGLevel = Math.ceil(element.AVGLevel)
                                this.series.axis.push(element.year)
                                this.series.min.push(Math.ceil(element.MinLevel))
                                this.series.max.push(Math.ceil(element.MaxLevel))
                                this.series.avg.push(Math.ceil(element.AVGLevel))
                            })
                            break;
                    }

                    this.items = data
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            }
        },
    },
}

</script>