<template>
  <v-tabs vertical class="elevation-2 mt-2">
    <v-tab v-for="(item, index) in items" :key="index" class="text-left">
      <v-icon left>
        mdi-hvac
      </v-icon>
      {{ item.name }}
    </v-tab>
    <v-tab-item v-for="(item, index) in items" :key="index">
      <MAP :plant="item" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import MAP from '@/components/waste/waste_map.vue'
export default {
  components: {
    MAP,
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    isSuperAdmin: {
      get: function () {
        return this.$store.state.User.isSupperAdmin
      }
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      },
    },
    assets() {
      return this.$store.state.Asset.assetAll
    },
    role: {
      get: function () {
        return this.$store.state.User.role
      }
    },
  },
  mounted() {
    this.getWTP()
  },
  watch: {
    building() {
      this.getWTP()
    }
  },
  methods: {
    getWTP() {
      this.loading = true
      this.$store.dispatch('Waste/GET_WTP', { BuildingId: this.building.BuildingId }).then((data) => {
        data.forEach(wtp => {
          wtp.asset = this.assets.filter((asset) => {
            return wtp.assetId.filter((assetId) => {
              return assetId === asset.assetId
            }).length > 0
          });
          console.log(data)
          this.items = data
          this.loading = false
        })
      }).catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
  }
}

</script>