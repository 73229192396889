<template>
    <v-card>
        <v-card-title> {{ formTitle }} <v-spacer /> <v-switch label="Notify" :true-value="true" :false-value="false"
                :indeterminate="true" v-model="editedItem.notify" :disabled="role !== 'Admin'"></v-switch> <v-btn icon
                @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
            <v-snackbar v-model="errorSnack" :timeout="timeout">
                {{ errorMessage }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <v-row dense class="mt-2">
            </v-row>
            <span class="font-weight-bold"> General Information</span>
            <v-card class="mb-4" :disabled="loading" flat outlined>
                <v-card-text>
                    <v-form ref="form" :valid="valid">
                        <v-row dense class="mt-2">
                            <v-col cols="12" md="4"><v-text-field :disabled="(editedIndex === -1) ? false : true"
                                    label="AssetID" :rules="[v => !!v || 'Please specify']"
                                    v-model="editedItem.assetId"></v-text-field></v-col>
                            <v-col cols="12" md="4"><v-text-field :disabled="role !== 'Admin'" label="Asset Name"
                                    v-model="editedItem.assetName"
                                    :rules="[v => !!v || 'Please specify']"></v-text-field></v-col>
                            <v-col cols="12" md="4"><v-text-field :disabled="role !== 'Admin'" label="Device Id"
                                    v-model="editedItem.deviceId"
                                    :rules="[v => !!v || 'Please specify']"></v-text-field></v-col>
                            <v-col cols="12" md="6"><v-text-field :disabled="role !== 'Admin'" label="Location"
                                    v-model="editedItem.location"
                                    :rules="[v => !!v || 'Please specify']"></v-text-field></v-col>
                            <v-col cols="12" md="6"><v-select :disabled="role !== 'Admin'" :items="buildingList"
                                    item-text="BuildingName" item-value="BuildingId" label="Building name"
                                    v-model="editedItem.buildingId"
                                    :rules="[v => !!v || 'Please specify']"></v-select></v-col>
                            <v-col cols="12" md="6"><v-select :disabled="role !== 'Admin'" label="Asset Type"
                                    :items="assetTypeItems" v-model="editedItem.assetType" @change="updateAssetType"
                                    :rules="[v => !!v || 'Please specify']" return-object></v-select></v-col>

                            <v-col cols="12" md="6"><v-select :disabled="role !== 'Admin'" :items="services"
                                    item-text="serviceName" item-value="serviceId" label="Service"
                                    v-model="editedItem.serviceId"
                                    :rules="[v => !!v || 'Please specify']"></v-select></v-col>

                            <v-col cols="12" md="2"><v-text-field :disabled="role !== 'Admin'" label="Floor"
                                    v-model="editedItem.floor"
                                    :rules="[v => !!v || 'Please specify']"></v-text-field></v-col>
                            
                            <v-col cols="12" md="2"><v-text-field type="number" :disabled="role !== 'Admin'" label="Min"
                                        v-model="editedItem.min"></v-text-field></v-col>
                            <v-col cols="12" md="2"><v-text-field type="number" :disabled="role !== 'Admin'" label="Max"
                                            v-model="editedItem.max"></v-text-field></v-col>
                            <v-col cols="12" md="3"><v-text-field :disabled="role !== 'Admin'"  label="Position"
                                    v-model="editedItem.position"></v-text-field></v-col>
                            <v-col cols="12" md="3"><v-text-field :disabled="role !== 'Admin'"  label="Icon size"
                                    v-model="editedItem.IconSize"></v-text-field></v-col>  
                            <v-col cols="12" md="3"><v-checkbox :disabled="role !== 'Admin'" label="Log data"
                                    v-model="editedItem.log"></v-checkbox></v-col>
                            <v-col cols="12" md="3"><v-checkbox :disabled="role !== 'Admin'" label="Is machine"
                                    v-model="editedItem.machine"></v-checkbox></v-col>
                            <v-col cols="12" md="6"></v-col>
                            <v-col cols="12" md="6"><v-text-field :disabled="role !== 'Admin'" label="Asset Icon URL (For Dashboard layout)"
                                    v-model="editedItem.icon"></v-text-field></v-col>
                            <v-col cols="12" md="6"><v-text-field :disabled="role !== 'Admin'" label="Asset Image URL"
                                    v-model="editedItem.MapImage"></v-text-field></v-col>
                            <v-col cols="12" md="6">
                                Preview
                                <v-img contain width="200" :src="editedItem.icon || require('@/assets/undraw_photos_re_pvh3.svg')" ></v-img>
                            </v-col>
                            <v-col cols="6">
                                Preview
                                <v-img contain width="200" :src="editedItem.MapImage || require('@/assets/undraw_photos_re_pvh3.svg')"></v-img>
                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
            <span class="font-weight-bold"> Notification </span>
            <v-card class="mb-4" :disabled="loading" flat outlined>
                <v-card-text>
                    <v-row dense class="mt-2">
                        <v-col cols="auto"><v-checkbox :true-value="true" :false-value="false"
                                :disabled="role !== 'Admin'" label="Show data"
                                v-model="editedItem.showData"></v-checkbox></v-col>
                        <v-col cols="auto"><v-checkbox :true-value="true" :false-value="false"
                                :disabled="role !== 'Admin'" label="Repeat notify"
                                v-model="editedItem.repeateNotify"></v-checkbox></v-col>
                        <v-col cols="auto"><v-checkbox :true-value="true" :false-value="false"
                                :disabled="role !== 'Admin'" label="Maintenance"
                                v-model="editedItem.maintenance"></v-checkbox></v-col>
                        <v-col cols="auto"><v-checkbox :true-value="true" :false-value="false"
                                :disabled="role !== 'Admin'" label="Debug mode"
                                v-model="editedItem.debug"></v-checkbox></v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <span class="mt-4 font-weight-bold">Connection </span>
            <v-card class="mb-4" :disabled="loading" flat outlined>
                <v-card-text>
                    <v-row dense class="mt-2">
                        <v-col cols="6"><v-select :disabled="role !== 'Admin'" label="API Connection" item-text="rowKey"
                                item-value="rowKey" :items="connectorItems"
                                v-model="editedItem.connection"></v-select></v-col>
                        <v-col cols="6"><v-select :disabled="role !== 'Admin'" label="IoT Interface"
                                :items="IoTInterfaceItems" v-model="editedItem.interface"></v-select></v-col>
                        <v-col cols="12"><v-text-field :disabled="role !== 'Admin'" label="Pattern"
                                v-model="editedItem.pattern"></v-text-field></v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-spacer /><v-btn v-if="role === 'Admin'" color="primary" :loading="loading" @click="save">Save</v-btn>
        </v-card-actions>
    </v-card>

</template>
<script>
// import { getAssetType } from '@/api';
// import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        asset: {
            type: Object
        },
    },
    watch: {
        asset(val) {
            if (val) {
                this.editedItem = Object.assign({}, val);
                this.editedItem.assetType = this.assetTypeItems.find(x => x.value === val.assetType)
                if (val.assetId) {
                    this.editedIndex = 1
                } else {
                    this.editedIndex = -1
                    this.editedItem = Object.assign({}, {});
                    this.editedItem.buildingId = this.building
                }
            }
        }
    },
    computed: {
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
        formTitle() {
            return this.editedIndex === -1 ? 'New Asset' : 'Edit Asset'
        },
        buildingList() {
            return this.$store.state.User.buildingList
        },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            }
        },
    },
    data() {
        return {
            valid: false,
            dialog: false,
            errorSnack: false,
            errorMessage: '',
            timeout: 2000,
            loading: false,
            editedItem: {},
            editedIndex: -1,
            services: [],
            assetTypeItems: [
                { text: 'Power Meter', value: 'PowerMeter' },
                { text: 'Duct Smoke', value: 'DuctSmoke' },
                { text: 'Smoke CO Detector', value: 'Smoke&CO' },
                { text: 'AQI', value: 'AQI' },
                { text: 'Water Level', value: 'WaterLevel' },
                { text: 'Generator', value: 'Generator' },
                { text: 'FCP', value: 'FCP' },
                { text: 'Water Pressure', value: 'WaterPressure' },
                { text: 'Water Meter', value: 'WaterMeter' }
            ],
            connectorItems: [],
            IoTInterfaceItems: [
                { text: 'zeta', value: 'zeta' },
                { text: 'inknow', value: 'inknow' },
                { text: 'BAS', value: 'BAS' },
            ]
        }
    },
    mounted() {
        this.editedItem = Object.assign({}, this.asset);
        if (this.asset.assetId) {
            this.editedIndex = 1
        }
        this.getConnector()
        this.getAssetType()
        this.getServices()
        this.editedItem.buildingId = this.building
    },
    methods: {
        updateAssetType(value) {
            this.editedItem.pattern = value.pattern
        },
        getConnector() {
            this.$store.dispatch('Network/GET_CONNECTOR',{buildingId: this.building.BuildingId}).then((connectors) => {
                this.connectorItems = connectors
            }).catch(() => {
                this.connectorItems = []
            })
        },
        getAssetType() {
            this.$store.dispatch('Asset/GET_ASSET_TYPE').then((data) => {
                data.forEach(element => {
                    element.text = element.partitionKey
                    element.value = element.rowKey
                });
                this.assetTypeItems = data
            }).catch(() => {
                this.assetTypeItems = []
            })
        },
        getServices() {
            this.$store.dispatch('Asset/GET_SERVICES').then((data) => {
                data.forEach(element => {
                    element.text = element.serviceName
                    element.value = element.serviceId
                });
                this.services = data
            }).catch(() => {
                this.services = []
            })
        },
        save() {
            if (!this.$refs.form.validate()) return
            this.loading = true
            const data = {
                serviceId: this.editedItem.serviceId,
                IconSize: this.editedItem.IconSize,
                MapImage: this.editedItem.MapImage,
                assetName: this.editedItem.assetName,
                assetType: this.editedItem.assetType.value,
                connection: this.editedItem.connection,
                building: this.editedItem.building,
                floor: this.editedItem.floor,
                icon: this.editedItem.icon,
                interface: this.editedItem.interface,
                location: this.editedItem.location,
                logs: (this.editedItem.logs) ? this.editedItem.logs : false,
                pattern: this.editedItem.pattern,
                position: this.editedItem.position,
                showData: (this.editedItem.showData) ? this.editedItem.showData : false,
                deviceId: this.editedItem.deviceId,
                child: this.editedItem.child,
                assetId: this.editedItem.assetId,
                debug: (this.editedItem.debug) ? this.editedItem.debug : false,
                maintenance: (this.editedItem.maintenance) ? this.editedItem.maintenance : false,
                notify: (this.editedItem.notify) ? this.editedItem.notify : false,
                repeateNotify: (this.editedItem.repeateNotify) ? this.editedItem.repeateNotify : false,
                debug: (this.editedItem.debug) ? this.editedItem.debug : false,
                machine: (this.editedItem.machine) ? this.editedItem.machine : false,
                min: this.editedItem.min,
                max: this.editedItem.max
            }
            this.$store.dispatch('Asset/UPDATE_ASSET', data).then((data) => {
                this.loading = false
                this.$store.dispatch('Asset/GET_ALL_ASSET', this.building).then(()=>{
                    this.errorMessage = 'Update Success',
                    this.errorSnack = true
                }).catch((err)=>{
                    console.log(err)
                })
            }).catch((error) => {
                this.loading = false
                this.errorMessage = error
                this.errorSnack = true
            })


        }
    },
}

</script>