<template>
    <v-container fluid>
      <Head>
        <template v-slot:title>
          <v-btn icon :to="{name: 'overview'}"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-icon color="brown">mdi-volume-off</v-icon>Maintenance
        </template>
        <template v-slot:subtitle>
        รายงานสถานะอุปกรณ์ปิดปรับปรุง
      </template>
        </Head>
        <v-row>
          <v-col cols="12">
       <DataTable/>
          </v-col>
        </v-row>    
    </v-container>
  </template>
  
  <script>
  import Head from '@/components/layout/Head.vue'
  import DataTable from '@/components/maintenance/maintenance_table.vue'

    export default {
      name: 'MaintenanceStatusView',
      components: {
        Head,
        DataTable,
      },
     data() {
        return {
          tab: null
        }
     },
    }
  </script>
  