import Vue from 'vue'
import VueRouter from 'vue-router'
import { registerGuard } from './guard'
import OverviewView from '../views/OverviewView.vue'
import SmartLevel from '../views/SmartLevelView.vue'
import EnergyManagementView from '../views/EnergyManagementView.vue'
import EnergyAnalysisView from '../views/EnergyAnalysisView.vue'
import FireAlarmView from '../views/FireAlarmView.vue'
import FireAlarmHistoryView from '../views/FireAlarmHistoryView.vue'
import FireAlarmReportView from '../views/FireAlarmReportView.vue'
import ReportView from '../views/ReportView.vue'
import AssetView from '../views/AssetView.vue'
import NetworkView from '../views/NetworkView.vue'
import ElectricalView from '../views/ElectricalView.vue'
import ColdWaterView from '../views/ColdWaterView.vue'
import WasteWaterView from '../views/WasteWaterView.vue'
import HVACView from '../views/HVACView.vue'
import AlertView from '../views/AlertView.vue'
import MachineView from '../views/MachineView.vue'
import UsersView from '../views/UsersView.vue'
import AirQualityView from '../views/AirQualityView.vue'
import HotWaterView from '@/views/HotWaterView.vue'
import RainWaterView from '@/views/RainWaterView.vue'
import SoftWaterView from '@/views/SoftWaterView.vue'
import MaintenanceStatusView from '@/views/MaintenanceStatusView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: OverviewView,
    //redirect: {name: 'overview'},
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/overview',
    name: 'overview',
    component: OverviewView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/asset',
    name: 'asset',
    component: AssetView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/alert',
    name: 'alert',
    component: AlertView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/machine',
    name: 'machine',
    component: MachineView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/network',
    name: 'network',
    component: NetworkView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/electrical',
    name: 'electrical',
    component: ElectricalView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/cold_water',
    name: 'ColdWater',
    component: ColdWaterView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/waste_water',
    name: 'WasteWater',
    component: WasteWaterView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/hot_water',
    name: 'HotWater',
    component: HotWaterView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/rain_water',
    name: 'RainWater',
    component: RainWaterView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/soft_water',
    name: 'SoftWater',
    component: SoftWaterView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }

  },
  {
    path: '/hvac',
    name: 'hvac',
    component: HVACView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/smart_level',
    name: 'smart_level',
    component: SmartLevel,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/energy_management',
    name: 'energy_management',
    component: EnergyManagementView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/energy_analysis',
    name: 'energy_analysis',
    component: EnergyAnalysisView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/fire_alarm',
    name: 'fire_alarm',
    component: FireAlarmView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/fire_alarm_history',
    name: 'fire_alarm_history',
    component: FireAlarmHistoryView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/fire_alarm_report',
    name: 'fire_alarm_report',
    component: FireAlarmReportView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/air_quality',
    name: 'air_quality',
    component: AirQualityView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: MaintenanceStatusView,
    meta: {
      requiresAuth: true,
      scrollToTop: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requiresAuth: true,
    }
  }
]

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    // return savedPosition
    return new Promise(resolve => {
      if (savedPosition) {
        // create an Observer instance
        const resizeObserver = new ResizeObserver(entries => {
          console.log(savedPosition.y)
          console.log(entries[0].target.clientHeight)
          if (entries[0].target.clientHeight >= savedPosition.y) {
            console.log('observe fire')
            resolve(savedPosition)
            resizeObserver.disconnect()
          }
        })
        // start observing a DOM node
        resizeObserver.observe(document.body)
      } else {
        resolve({ top: 0 })
      }
    })
  } else {
    const position = {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash

      // specify offset of the element
      if (to.hash === '#anchor2') {
        position.offset = { y: 100 }
      }

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0
        position.y = 0
      }
      // wait for the out transition to complete (if necessary)
      this.app.$root.$once('triggerScroll', () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        resolve(position)
      })
    })
  }
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

registerGuard(router)

export default router
