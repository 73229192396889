<template>
    <v-card>
        <v-card-title>Data History | {{ asset.assetName }} ({{ asset.deviceId }}) {{ asset.assetType }}<v-spacer />
            <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-subtitle class="pt-1"> <span cols="auto" class="font-weight-bold">
                ข้อมูลปัจจุบัน เมื่อเวลา {{ $dayjs(asset.heartbeat).format('HH:mm DD MMM YYYY') }}
            </span>
            <span v-for="(subItem, index) in Object.keys(JSON.parse(asset.data))" :key="index">
                {{ subItem }} : {{ JSON.parse(asset.data)[subItem] }} {{ units[subItem] }}
            </span>
        </v-card-subtitle>
        <v-card-text>
            <v-snackbar v-model="errorSnack" :timeout="timeout">
                {{ errorMessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>

            <v-row>
                <v-spacer />
                <v-col cols="auto" class="mt-2">
                    เลือกช่วงเวลา
                </v-col>
                <v-col cols="auto">
                    <DateRangePicker v-model="range" />
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" :disabled="loading" @click="getData">View</v-btn>
                </v-col>
                <v-col cols="auto" v-if="isSuperAdmin">
                    <v-btn color="primary" outlined :disabled="loading" @click="anomalyLearning">Learning</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" outlined :disabled="loading"
                        @click="exportToXLS(`Export${$dayjs().format('DDMMMYYYYHHmmss')}`, { headers: headers, items: items })"><v-icon>mdi-export</v-icon>Export</v-btn>
                </v-col>
            </v-row>

            <v-tabs v-model="tab">
                <v-tab href="#tab-table">Data Table</v-tab>
                <v-tab href="#tab-chart">Chart</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">

                <v-tab-item :value="'tab-table'">
                    <v-data-table :search="search" :headers="headers" :loading="loading" :items="items" sort-by="timestamp" :sort-desc="true">
                        <template v-slot:top>
                            <v-row class="mt-2">
                                <v-col cols="auto">
                                    <v-text-field v-model="search" solo dense prepend-inner-icon="mdi-magnify"
                                        label="Search" />
                                </v-col>

                                <v-spacer />

                            </v-row>
                        </template>

                        <template v-slot:item.timestamp="{ item }">
                            {{ $dayjs(item.timestamp).format('HH:mm DD MMM YYYY') }}
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item :value="'tab-chart'">
                    <AssetChart :data="items" />
                    <!-- <div ref="linechart" class="mt-2" style="width: 100%; height: 450px;"></div> -->
                </v-tab-item>
            </v-tabs-items>

        </v-card-text>
    </v-card>

</template>
<script>
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import AssetChart from '@/components/asset/asset_chart.vue'
import { xlsxMixins } from '@/mixins/xlsx'
import { anomalyLearning } from '@/api';

export default {
    props: {
        asset: {
            type: Object
        },
    },
    components: {
        DateRangePicker,
        AssetChart
    },
    mixins: [xlsxMixins],
    watch: {
        asset(val) {
            if (val.assetId) {
                this.range = {
                    startDate: this.$dayjs().subtract(1, 'day').toDate(),
                    endDate: this.$dayjs().endOf('day').toDate(),
                }
                this.getData()
            }
        },
        tab(val) {
            if (val) {
                this.getData();
            }
        }
    },
    computed: {
        units() {
            return this.$store.state.User.units
        },
        role: {
            get: function () {
                return this.$store.state.User.role
            }
        },
        isSuperAdmin: {
            get: function () {
                return this.$store.state.User.isSupperAdmin
            }
        },
    },
    data() {
        return {
            myChart: null,
            tab: null,
            range: {
                startDate: this.$dayjs().subtract(1, 'day').toDate(),
                endDate: this.$dayjs().endOf('day').toDate(),
            },
            search: '',
            errorSnack: false,
            errorMessage: '',
            timeout: 2000,
            headers: [],
            items: [],
            loading: false,

        }
    },
    mounted() {
        if (this.asset.assetId) {
            this.range = {
                startDate: this.$dayjs().subtract(1, 'day').toDate(),
                endDate: this.$dayjs().endOf('day').toDate(),
            }
            this.getData();
        }
    },
    methods: {
        anomalyLearning() {
            this.loading = true
            this.$store.dispatch('Asset/UPDATE_ANOMALY_LEARNING', { assetId: this.asset.assetId, start: Math.floor(this.range.startDate.getTime() / 1000), end: Math.floor(this.range.endDate.getTime() / 1000) }).then(() => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getData() {
            this.loading = true
            this.items = []
            this.$store.dispatch('Asset/GET_TELEMETRY', { assetId: this.asset.assetId, start: Math.floor(this.range.startDate.getTime() / 1000), end: Math.floor(this.range.endDate.getTime() / 1000) }).then((data) => {
                this.items = data
                if (data.length > 0) {
                    const elements = Object.keys(data[0])
                    const headers = []
                    for (let i = 0; i < elements.length; i++) {
                        if (elements[i] === 'timestamp') {
                            headers.unshift({ text: elements[i], value: elements[i], align: 'start' })
                        } else {
                            headers.push({ text: elements[i], value: elements[i] })
                        }
                    }
                    this.headers = headers
                }

                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },

    },
}

</script>