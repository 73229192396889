<template>
    <v-row class="pt-2">
       <v-col cols="6" v-for="(item,index) in assets" :key="index">
        <LevelChart :meter="item"></LevelChart>
       </v-col> 
    </v-row>
   </template>
   <script>
   import LevelChart from '@/components/sanitary/chart/level_chart.vue'
   import { xlsxMixins } from '@/mixins/xlsx'
   export default {
     mixins: [xlsxMixins],
     components: {
        LevelChart,
     },
       data() {
           return {
               heartbeats: [120,100,90,110,130],
               enable: true,
               selectedMeter: null,
               loading: false,
               headers: [
                {text: 'Year', value: 'year'},
                {text: 'Month', value: 'month'},
                {text: 'Peak', value: 'Peak'},
                {text: 'OffPeak', value: 'OffPeak'},
                {text: 'Total', value: 'Unit'},
                {text: 'Demand', value: 'demand'},
               ]
           }
       },
       computed: {
           assets () {
               return this.$store.state.Asset.assetSoftWater.filter((el)=>el.assetType ==='WaterLevel') 
           },
        building: {
            get: function () {
                return this.$store.state.User.building
            },
            set: function (newValue) {
                this.$store.dispatch('User/SET_BUILDING', newValue)
            },
        },
        items: {
            get: function () {
            return this.$store.state.Water.waterTable
            },
            set: function (newValue) {
            }
        }
       },
       methods: {
        getWaterTable() {
            this.loading = true
            this.$store.dispatch('Water/GET_WATER_TABLE', { BuildingId: this.building.BuildingId, type: 'monthly', deviceId: this.selectedMeter, period: 12 }).then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
       },
   }
   
   </script>