<template>
<v-overlay :absolute="true" :opacity="0.4" :value="asset.maintenance">
    <div class="text-center">
    <v-icon x-large>mdi-wrench</v-icon>
   
    <div class="text-h4">อยู่ระหว่างการซ่อมบำรุง</div>
    <ASSET_MENU class="mt-4" :asset="asset" :align="'center'"/>
</div>
</v-overlay>
</template>

<script>
import ASSET_MENU from '@/components/asset/asset_menu.vue'
export default {
    props: {
        asset: {
            type: Object,
            default: {}
        },
    },
    components: {
        ASSET_MENU
    },
    props: {
        asset: {
            type: Object
        },
    },
}
</script>