<template>
      <v-row v-if="assets.length > 0">
        <v-col cols="12"  md="10">
        </v-col>
        <v-col cols="12" md="2">
            <v-text-field v-model="search" label="ค้นหา" append-icon="mdi-magnify" single-line solo dense hide-details></v-text-field>
        </v-col>
            <v-col cols="12" md="4" v-for="(item, index) in fillterdAsset" :key="index">
                <MachineStatusWidget class="" :device="item" :detail="item.assetName" :content="item.assetType"
                    :timestamp="item.heartbeat" :image="item.MapImage" :status="item.data" />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-card flat>
                    <v-card-text class="text-subtitle-1 text-center">
                        No Machine Found
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
</template>
<script>
import MachineStatusWidget from '@/components/machine/MachineStatusWidget.vue'

export default{
    components: {
        MachineStatusWidget
    },
    props: {
        assets: {
            type: Array,
            default: []
        }
    },
    computed: {
        fillterdAsset() {
            return this.assets.filter((asset) => {
                return asset.assetName.toLowerCase().includes(this.search.toLowerCase())
            }) 
        }
    },
    data() {
        return {
            search: ''
        }
    },
}
</script>