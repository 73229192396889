<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 6 : 2" color="grey lighten-3">
      <v-overlay :absolute="true" :opacity="0.4" :value="device.maintenance">
        <div class="text-center">
          <v-icon x-large>mdi-wrench</v-icon>
          <div class="text-h4">อยู่ระหว่างการซ่อมบำรุง</div>
        </div>
      </v-overlay>
      <v-card-title dense class="text-h6 font-weight-bold">
        <v-avatar>
          <v-tooltip bottom v-if="device.status === 'Online'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="32" color="primary">mdi-engine</v-icon>
            </template>
            <span>ออนไลน์</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="32" color="red">mdi-engine</v-icon>
            </template>
            <span>ออฟไลน์</span>
          </v-tooltip>
        </v-avatar>
        {{ detail }}<v-spacer></v-spacer>
        <ASSET_ALERT_ICON :asset="device" />
      </v-card-title>
      <v-card-subtitle dense>
        <div>
          {{ content }}
        </div>
        <div>
          {{ device.location }}
        </div>
      </v-card-subtitle>
      <v-card color="white" flat>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" xs="10" md="8">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="font-weight-bold text-h5">{{ device.machineState }}</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table dense class="mt-2">
                      <thead>
                        <tr>
                          <th class="text-left">
                            Parameter
                          </th>
                          <th class="text-left">
                            Value
                          </th>
                          <th class="text-left">
                            Unit
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="device.data">
                        <tr v-for="(subItem, index) in Object.keys(JSON.parse(device.data))" :key="index">
                          <td class="center-text" width="100px">
                            {{ subItem }}
                          </td>
                          <td class="center-text">
                            {{ JSON.parse(device.data)[subItem] }}
                          </td>
                          <td class="center-text">{{ units[subItem] }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class="text-caption">{{ $dayjs(device.heartbeat).format('LLL') }}</div>
            </v-col>
            <v-col cols="12" xs="2" md="4">
              <v-hover v-slot="{ hover }">
                <v-img contain class="mt-n12" height="120" :src="image || require('@/assets/undraw_photos_re_pvh3.svg')"
                  :lazy-src="require('@/assets/undraw_photos_re_pvh3.svg')">
                </v-img>
              </v-hover>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text class="text-left">
              <ASSET_MENU :asset="device" />
            </v-card-text>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </v-hover>
</template>

<script>
import ASSET_MENU from '@/components/asset/asset_menu.vue'
import ASSET_ALERT_ICON from '@/components/asset/asset_alert_icon.vue'

export default {
  props: {
    detail: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    timestamp: {
      type: String,
      default: 0
    },
    device: {
      type: Object
    }
  },
  components: {
    ASSET_MENU,
    ASSET_ALERT_ICON
  },
  data() {
    return {
      assetType: []
    }
  },
  computed: {
    units() {
      return this.$store.state.User.units
    }
  },
  methods: {

  },
}
</script>