<template>
  <v-container fluid>
    <Head>
      <template v-slot:title>
        <v-btn icon :to="{ name: 'overview' }"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-icon color="blue-grey">mdi-recycle</v-icon>Waste Water
      </template>
      <template v-slot:subtitle>
        ระบบน้ำเสีย
      </template>
    </Head>
    <v-tabs centered v-model="tab" v-if="sanitary_machine.length > 0">
      <v-tab v-if="main_level.length > 0 || main_meter.length > 0">Dashboard</v-tab>
      <v-tab v-if="hasWTP">Plant</v-tab>
      <v-tab v-if="main_level.length > 0 || main_meter.length > 0">History</v-tab>
      <v-tab>Machine</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" v-if="sanitary_machine.length > 0">
      <v-tab-item v-if="main_level.length > 0 || main_meter.length > 0">
        <v-card flat>
          <v-card-text>
            <Dashboard />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="hasWTP">
        <v-card flat>
          <v-card-text>
          <Plant/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="main_level.length > 0 || main_meter.length > 0">
        <v-card flat>
          <v-card-text>
            <Table />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <MACHINE_VIEW :assets="sanitary_machine" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-card v-else flat>
      <v-card-title> <v-spacer /> This feature not available for your building<v-spacer /> </v-card-title>
      <v-img :src="require('@/assets/undraw_city_life_gnpr.svg')">
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
import Head from '@/components/layout/Head.vue'
import Dashboard from '@/components/waste/sanitary_dashboard'
import ColdWater from '@/components/waste/sanitary_coldwater'
import WasteWater from '@/components/waste/sanitary_wastewater'
import Table from '@/components/waste/sanitary_table'
import Level from '@/components/waste/sanitary_level'
import MACHINE_VIEW from '@/components/machine/machine_view'
import Plant from '@/components/waste/waste_plant.vue'

export default {
  name: 'WasteWaterView',
  components: {
    Head,
    Dashboard,
    ColdWater,
    WasteWater,
    Table,
    Level,
    MACHINE_VIEW,
    Plant
  },
  computed: {
    main_meter() {
      return this.$store.state.Waste.mainWaterMeter
    },
    main_level() {
      return this.$store.state.Waste.mainWaterLevel
    },
    sanitary_machine() {
      return this.$store.state.Asset.assetAll.filter(el => el.machine === true && el.serviceId === '78fe3ec8-4e94-4b19-8869-1c06417a8a19')
    },
    building: {
      get: function () {
        return this.$store.state.User.building
      },
      set: function (newValue) {
        this.$store.dispatch('User/SET_BUILDING', newValue)
      },
    }
  },
  data() {
    return {
      tab: null,
      hasWTP: false
    }
  },
  mounted() {
    this.getWTP();
  },
  methods: {
    getWTP() {
      this.loading = true
      this.$store.dispatch('Waste/GET_WTP', { BuildingId: this.building.BuildingId }).then((data) => {
        if (data.length > 0) {
          this.hasWTP = true
        }
      }).catch((error) => {
        console.log(error)
        this.loading = false
      })
    },
  },
}
</script>